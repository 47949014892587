import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const NadmorskiPage = () => (
  <Layout>
    <SEO title="Nadmorski Weekend w Gdańsku" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/nadmorski-weekend.jpg`} alt="" />
        </div>
        <div>
          <h3>ZAPRASZAMY NA WEEKEND NAD MORZEM BAŁTYCKIM</h3>
          <h1>Nadmorski Weekend w Gdańsku!</h1>
          <p>
            Wychowywanie się nad Zatoką Gdańską sprawiło, że bardzo kochamy
            wodę. Cieszymy się, że możemy podzielić się z Wami naszym najlepszym
            doświadczeniem związanym ze sportem wodnym. Rejs katamaranem w
            Sopocie to miła i relaksująca wycieczka. Zobaczysz najdłuższe
            drewniane molo na Bałtyku, linię brzegową Gdańska, Sopotu i Gdyni, a
            przede wszystkim Zatokę Gdańską. Lokalna kuchnia pomorska jest
            wyśmienitą ucztą, a szczególnie dla miłośników ryb. Po wizycie w
            restauracji warto spalić kalorie na wodnym skuterze bądź jeżeli
            wolicie, można udać się w rejs statkiem stylizowanym na XVII wiek.
            Na pokładzie rejs umila muzyka na żywo oraz bar.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>
                  Zwiedzanie Sopotu, rejs katamaranem w Sopocie po Zatoce
                  Gdańskiej
                </li>
                <li>Lunch na statku lub w nadmorskiej restauracji w Sopocie</li>
                <li>Zabawa na skuterach wodnych</li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>Rejs na Westerplatte statkiem stylizowanym na XVII w.</li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NadmorskiPage
